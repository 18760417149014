import { useEffect } from 'react'

const useAudio = (url) => {
  useEffect(() => {
    const audio = new Audio(url)
    console.log(audio)
    audio.volume = 0.3
    audio.play()
    return () => {
      audio.pause()
    }
  }, [url])
}

export default useAudio