import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import useAudio from '../../hooks/use-audio'
import { Link } from 'gatsby'

const NochePage = () => {
  useAudio("https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/noche.mp3")
  return (
    <Layout>
      <nav class="navbar">
        <Link class="navbar-brand" to="/">Volver</Link>
      </nav>
      <SEO title="Noche" />
      <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-11.jpg" alt="Arbol" />
      <div className="row align-items-center mt-5">
        <div className="col-12 col-md-8">
          <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-24.jpg" alt="Arbol" />

        </div>
        <div className="col-12 col-md-4">
          <h1 className="text-center">Acto Noche</h1>
          <p className="text-center">
            Alguien va a soñar algo.
            <br />
            <br />
            Las revelaciones nacen de los sueños.
                </p>
        </div>
      </div>
    </Layout>
  )
}

export default NochePage
